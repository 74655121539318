import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { fontSize, media } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const TriangleSvg = styled.img`
  position: absolute;
  top: -33px;
  right: -140px;
  ${media.xl} {
    right: -300px;
  }
  ${media.sm} {
    top: 305px;
    height: 550px;
    right: -140px;
  }
`;

export const TriangleWrapper = styled.div`
  z-index: -1;
`;

export const Illustration = styled(GatsbyImage)`
  position: absolute !important;
  top: -185px;
  right: -132px;
  ${media.xl} {
    right: -72px;
  }
  ${media.lg} {
    top: -100px;
    right: -22px;
    max-height: 500px;
    max-width: 45%;
  }
  ${media.md} {
    position: relative !important;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    top: 0;
    right: auto;
    margin-left: 5px;
    margin-right: auto;
    max-width: initial;

    img {
      object-fit: contain !important;
    }
  }
  ${media.xs} {
    max-height: 370px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.lg} {
    padding-right: 0;
  }
  ${media.md} {
    flex-direction: column;
  }
`;

export const BottomText = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 1px;
  color: #a7aec1;
  text-transform: uppercase;
  ${media.md} {
    margin-bottom: 20px;
  }
  ${media.sm} {
    font-size: 11px;
    text-align: center;
  }
`;

export const StyledButton = styled(Button)`
  margin-bottom: 145px;
  ${media.lg} {
    margin-bottom: 80px;
  }
  ${media.md} {
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 55px;
  font-weight: normal;
  ${media.sm} {
    font-size: 38px;
    line-height: 1.26;
    margin-bottom: 25px;
  }
`;

export const LabelText = styled.span`
  font-size: ${fontSize.sm};
  font-weight: 500;
  line-height: normal;
  color: #757395;
  ${media.sm} {
    font-size: 12px;
  }
`;

export const StyledLabelIcon = styled.img`
  flex-shrink: 0;
  margin-right: 20px;

  ${media.sm} {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  ${media.md} {
    margin-bottom: 16px;
  }
`;

export const Content = styled.div`
  ${media.md} {
    margin-bottom: 30px;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 210px;
  padding-bottom: 160px;
  overflow: hidden;
  ${media.lg} {
    padding-bottom: 100px;
  }
  ${media.md} {
    padding-top: 114px;
    padding-bottom: 70px;
  }
`;
