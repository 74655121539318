import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { IntroView } from './employee-rewards-intro.view';

export const Intro = (props) => {
  const {
    illustrationFeatures: {
      childImageSharp: { fixed: illustrationFeaturesFixed },
    },
  } = useStaticQuery(graphql`
    query {
      illustrationFeatures: file(
        relativePath: { eq: "pages/employee-rewards/intro/illustration.png" }
      ) {
        childImageSharp {
          fixed(height: 688) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  return <IntroView {...props} illustration={illustrationFeaturesFixed} />;
};
