import { motion } from 'framer-motion';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Grid,
  ContentLeft,
  ContentRight,
  StyledTitle,
  Description,
  BackgroundImageWrapper,
  TriangleImage,
} from './employee-rewards-global-workforce.styles';
import IllustrtationActionSvgUrl from './images/illustration-action.url.svg';
import IllustrtationAustraliaSvgUrl from './images/illustration-australia.url.svg';
import IllustrtationAvatarSvgUrl from './images/illustration-avatar.url.svg';
import IllustrtationCanadaSvgUrl from './images/illustration-canada.url.svg';
import IllustrtationDenmarkSvgUrl from './images/illustration-denmark.url.svg';
import IllustrtationDescSvgUrl from './images/illustration-desc.url.svg';
import IllustrtationGlobalSvgUrl from './images/illustration-global.url.svg';
import IllustrtationNewZealandSvgUrl from './images/illustration-new-zealand.url.svg';
import IllustrtationSpainSvgUrl from './images/illustration-spain.url.svg';
import IllustrtationSwedenSvgUrl from './images/illustration-sweden.url.svg';
import IllustrtationUnitedStatesSvgUrl from './images/illustration-united-states.url.svg';
import TriangleSvgUrl from './images/triangle.url.svg';

export const GlobalWorkforce = ({ title, description }) => {
  const [animationContainer, animationContainerView] = useInView({
    threshold: 1,
  });

  const motionEase = [0.25, 0.1, 0.25, 1];

  const variantsContentFade = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      transition: { delay: custom, duration: 0.5, ease: motionEase },
    }),
  };

  const variantsAction = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      y: -50,
      transition: { delay: custom, duration: 0.5, ease: motionEase },
    }),
  };
  return (
    <Wrapper id="activation">
      <Inner>
        <Grid
          columns={2}
          gap={0}
          media={{
            md: { columns: 1 },
          }}
        >
          <GridCell>
            <ContentLeft ref={animationContainer}>
              <motion.div
                className="illustration-wrapper"
                initial="hidden"
                animate={animationContainerView && 'visible'}
              >
                <motion.img
                  src={IllustrtationGlobalSvgUrl}
                  className="illustration-global"
                  variants={{
                    hidden: {
                      opacity: 0,
                    },
                    visible: {
                      opacity: 1,
                      y: -70,
                    },
                  }}
                  transition={{ delay: 0, duration: 0.7, ease: motionEase }}
                />
                <motion.img
                  src={IllustrtationCanadaSvgUrl}
                  className="illustration-canada illustration-country"
                  variants={variantsAction}
                  custom={0.6}
                />
                <motion.img
                  src={IllustrtationSpainSvgUrl}
                  className="illustration-spain illustration-country"
                  variants={variantsAction}
                  custom={0.7}
                />
                <motion.img
                  src={IllustrtationUnitedStatesSvgUrl}
                  className="illustration-united-states illustration-country"
                  variants={variantsAction}
                  custom={0.8}
                />
                <motion.img
                  src={IllustrtationSwedenSvgUrl}
                  className="illustration-sweden illustration-country"
                  variants={variantsAction}
                  custom={0.9}
                />
                <motion.img
                  src={IllustrtationDenmarkSvgUrl}
                  className="illustration-denmark illustration-country"
                  variants={variantsAction}
                  custom={1}
                />
                <motion.img
                  src={IllustrtationNewZealandSvgUrl}
                  className="illustration-new-zealand illustration-country"
                  variants={variantsAction}
                  custom={1.1}
                />
                <motion.img
                  src={IllustrtationAustraliaSvgUrl}
                  className="illustration-australia illustration-country"
                  variants={variantsAction}
                  custom={1.2}
                />
                <motion.img
                  src={IllustrtationAvatarSvgUrl}
                  className="illustration-avatar"
                  variants={variantsContentFade}
                  custom={1.3}
                />
                <motion.img
                  src={IllustrtationActionSvgUrl}
                  className="illustration-action"
                  variants={variantsAction}
                  custom={1.4}
                />
                <motion.img
                  src={IllustrtationDescSvgUrl}
                  className="illustration-desc"
                  variants={variantsAction}
                  custom={1.6}
                />
              </motion.div>
            </ContentLeft>
          </GridCell>
          <GridCell>
            <ContentRight>
              <StyledTitle
                as="h2"
                size="xl"
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(title),
                }}
              />
              <Description
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(description),
                }}
              />
            </ContentRight>
          </GridCell>
        </Grid>
      </Inner>
      <BackgroundImageWrapper>
        <TriangleImage src={TriangleSvgUrl} />
      </BackgroundImageWrapper>
    </Wrapper>
  );
};
