import { Link } from 'gatsby';
import * as React from 'react';

import { Button } from 'components/shared/button';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  CollectionWrapper,
  CollectionTop,
  CollectionBottom,
  Container,
  ContentWrapper,
  ContentHead,
  TriangleBg,
  TrianglePurpleDarkBg,
  ImageCard,
  ImageStatic,
  StyledTitle,
  SubTitle,
  ContentFooter,
  FooterLeft,
  FooterText,
  FooterTitle,
  FooterDescription,
} from './collection-gift-cards.styles';
import TriangleBackgroundThemePurpleSvgUrl from './images/background-collection-theme-purple.url.svg';
import TriangleBackgroundSvgUrl from './images/background-collection.url.svg';
import CollectionSvgUrl2 from './images/gift-card-1.url.svg';
import CollectionSvgUrl8 from './images/gift-card-10.url.svg';
import CollectionSvgUrl10 from './images/gift-card-11.url.svg';
import CollectionSvgUrl4 from './images/gift-card-2.url.svg';
import CollectionSvgUrl6 from './images/gift-card-3.url.svg';
import CollectionSvgUrl1 from './images/gift-card-4.url.svg';
import CollectionSvgUrl3 from './images/gift-card-5.url.svg';
import CollectionSvgUrl5 from './images/gift-card-6.url.svg';
import CollectionSvgUrl11 from './images/gift-card-7.url.svg';
import CollectionSvgUrl9 from './images/gift-card-8.url.svg';
import CollectionSvgUrl7 from './images/gift-card-9.url.svg';
import FooterLogoSvgUrl from './images/wegift-blue.url.svg';

export const Collection = (props) => {
  const { theme, title, description, bottomTitle, bottomDescription, buttonText, buttonLink } =
    props;

  const widthImageCard = 250;
  const heightImageCard = 145;
  return (
    <Wrapper>
      {theme === 'purple-dark' ? (
        <TrianglePurpleDarkBg src={TriangleBackgroundThemePurpleSvgUrl} alt="" />
      ) : (
        <TriangleBg src={TriangleBackgroundSvgUrl} alt="" />
      )}
      <Container withoutBgAndShadow>
        <ContentWrapper>
          <ContentHead>
            <StyledTitle
              theme={theme}
              as="h1"
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(title),
              }}
            />
            <SubTitle
              as="h2"
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(description),
              }}
            />
          </ContentHead>
          <CollectionWrapper>
            <CollectionTop>
              <ImageCard
                src={CollectionSvgUrl1}
                alt="Google Play"
                width={widthImageCard}
                height={heightImageCard}
              />
              <ImageCard
                src={CollectionSvgUrl2}
                alt="Nike"
                width={widthImageCard}
                height={heightImageCard}
              />
              <ImageCard
                src={CollectionSvgUrl3}
                alt="Addidas"
                width={widthImageCard}
                height={heightImageCard}
              />
              <ImageCard
                src={CollectionSvgUrl4}
                alt="Amazon"
                width={widthImageCard}
                height={heightImageCard}
              />
              <ImageCard
                src={CollectionSvgUrl5}
                alt="DoorDash"
                width={widthImageCard}
                height={heightImageCard}
              />
              <ImageCard
                src={CollectionSvgUrl6}
                alt="Tesco"
                width={widthImageCard}
                height={heightImageCard}
              />
            </CollectionTop>
            <CollectionBottom>
              <ImageCard
                src={CollectionSvgUrl7}
                alt="The Home Depot"
                width={widthImageCard}
                height={heightImageCard}
              />
              <ImageCard
                src={CollectionSvgUrl8}
                alt="TicketMaster"
                width={widthImageCard}
                height={heightImageCard}
              />
              <ImageCard
                src={CollectionSvgUrl9}
                alt="Apple"
                width={widthImageCard}
                height={heightImageCard}
              />
              <ImageCard
                src={CollectionSvgUrl10}
                alt="Uber"
                width={widthImageCard}
                height={heightImageCard}
              />
              <ImageCard
                src={CollectionSvgUrl11}
                alt="Starbucks"
                width={widthImageCard}
                height={heightImageCard}
              />
            </CollectionBottom>
          </CollectionWrapper>
          {bottomTitle && buttonText && (
            <ContentFooter>
              <FooterLeft>
                <ImageStatic src={FooterLogoSvgUrl} alt="WeGift" width={80} height={80} />
                <FooterText>
                  <FooterTitle as="h1">{bottomTitle}</FooterTitle>
                  <FooterDescription as="h2">{bottomDescription}</FooterDescription>
                </FooterText>
              </FooterLeft>
              <Button component={Link} theme="fill" to={buttonLink}>
                {buttonText}
              </Button>
            </ContentFooter>
          )}
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
