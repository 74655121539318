import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 31px;
  ${media.sm} {
    font-size: 24px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
  strong {
    font-weight: normal;
    color: #00c1de;
  }
`;

export const ContentLeft = styled.div`
  padding-top: 70px;
  ${media.md} {
    padding-top: 0;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.lg} {
    padding: 60px 0 0;
  }
  ${media.md} {
    display: flex;
    justify-content: center;
  }
  .illustration-wrapper {
    position: relative;
    height: 425px;
    width: 600px;
    ${media.lg} {
      height: 345px;
      width: 480px;
    }

    ${media.sm} {
      height: 425px;
    }

    ${media.xs} {
      height: 285px;
      width: 320px;
      min-width: 320px;
    }
  }
  .illustration-circle {
    position: absolute;
    top: 24px;
    right: 8.2%;
    max-width: 100%;
    ${media.sm} {
      top: 45px;
      right: auto;
    }
  }
  .illustration-content {
    position: absolute;
    top: -9.8%;
    right: -13.7%;
    width: 128%;
    ${media.sm} {
      top: -0.8%;
    }
  }
  .illustration-content-title {
    position: absolute;
    top: 30%;
    right: 50.3%;
    width: 33%;
  }
  .illustration-content-desc {
    position: absolute;
    top: 50%;
    right: 50.6%;
    width: 33%;
  }
  .illustration-content-diagram-title {
    position: absolute;
    top: 33%;
    right: 20%;
    width: 20.5%;
  }
  .illustration-content-diagram {
    position: absolute;
    top: 40.4%;
    right: 13.4%;
    width: 32.5%;
  }
`;

export const Grid = styled(SharedGrid)``;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 220px;
  ${media.sm} {
    margin-bottom: 100px;
  }
`;
