import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 41px;
  ${media.sm} {
    font-size: 24px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
  strong {
    font-weight: normal;
    color: #00c1de;
  }
`;

export const ContentLeft = styled.div`
  padding-top: 70px;
  ${media.md} {
    padding-top: 0;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  ${media.lg} {
    padding: 60px 0 0;
  }
  ${media.md} {
    display: flex;
    justify-content: center;
  }
  .illustration-wrapper {
    position: relative;
    height: 500px;
    width: 600px;

    ${media.xs} {
      height: 270px;
      width: 320px;
      min-width: 320px;
    }
  }
  .illustration-content {
    position: absolute;
    top: 37px;
    right: 14%;
    max-width: 100%;
    box-shadow: 14px 24px 100px -32px rgb(50 50 93 / 89%);
    border-radius: 12px;

    ${media.xs} {
      right: 0;
      max-width: 55%;
    }
  }
  .illustration-content-title {
    position: absolute;
    top: 3.1%;
    right: 22.4%;
    width: 32%;

    ${media.sm} {
      width: 37%;
    }

    ${media.xs} {
      top: -0.9%;
      right: 9.4%;
      width: 35%;
    }
  }
  .illustration-content-logo {
    position: absolute;
    top: 25.9%;
    right: 24.6%;
    width: 29%;

    ${media.sm} {
      top: 25.5%;
      right: 26.2%;
      width: 32%;
    }

    ${media.xs} {
      top: 23.5%;
      width: 33%;
      right: 12%;
    }
  }
  .illustration-content-avatar {
    position: absolute;
    top: 67.1%;
    right: 34.6%;
    width: 7.6%;
    ${media.sm} {
      right: 36.6%;
    }
    ${media.xs} {
      top: 69.1%;
      right: 22.6%;
      width: 9.6%;
    }
  }
  .illustration-content-desc {
    position: absolute;
    top: 77.7%;
    right: 17.4%;
    width: 42%;
    ${media.sm} {
      width: 46%;
    }
    ${media.xs} {
      top: 82.7%;
      right: 4.8%;
      width: 45%;
    }
  }
  .illustration-content-action {
    position: absolute;
    top: 22.8%;
    right: 56%;
    width: 46%;
    box-shadow: 2px 2px 22px rgba(26, 26, 67, 0.00871394),
      14px 46px 180px -35px rgba(50, 50, 93, 0.885162);
    border-radius: 12px;
    ${media.xs} {
      top: 28.8%;
      width: 52%;
      right: 51%;
    }
  }
  .illustration-content-business {
    position: absolute;
    top: 46.4%;
    right: 55.7%;
    width: 46%;
    box-shadow: 14px 24px 80px -32px rgba(50, 50, 93, 0.885162);
    border-radius: 12px;
    ${media.xs} {
      top: 54.4%;
      right: 51.7%;
      width: 52%;
    }
  }
`;

export const Grid = styled(SharedGrid)``;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 225px;

  ${media.lg} {
    margin-bottom: 100px;
  }
`;
