import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const TriangleImage = styled.img`
  position: absolute;
  top: -5px;
  left: -134px;
  ${media.sm} {
    top: 12px;
    left: -313px;
    height: 395px;
    height: 575px;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: #838791;
  margin: 0;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 14px;
    line-height: 1.43;
    white-space: initial;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: normal;
  line-height: 1.31;
  margin-bottom: 25px;
  ${media.lg} {
    br {
      display: none;
    }
  }
  ${media.sm} {
    font-size: 24px;
    line-height: 1.38;
    margin-bottom: 5px;
  }
  strong {
    font-weight: normal;
    color: #5871f4;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  ${media.lg} {
    padding: 60px 0 0;
  }

  ${media.md} {
    display: flex;
    justify-content: center;
  }

  .illustration-wrapper {
    position: relative;
    height: 588px;
    width: 600px;

    ${media.lg} {
      height: 516px;
      width: 510px;
    }

    ${media.xs} {
      height: 325px;
      width: 320px;
      min-width: 320px;
    }
  }

  .illustration-global {
    position: absolute;
    top: 6%;
    left: -2.5%;
    max-width: 100%;

    ${media.xs} {
      top: 10%;
    }
  }

  .illustration-country {
    position: absolute;
    box-shadow: 0 24px 39px rgba(44, 41, 93, 0.2987);
    border-radius: 12px;
  }

  .illustration-canada {
    top: 61%;
    left: -2.2%;
    width: 18%;
  }

  .illustration-spain {
    top: 31.5%;
    left: -4.6%;
    width: 16%;
  }

  .illustration-united-states {
    top: 7.6%;
    left: 17.1%;
    width: 23.5%;
  }

  .illustration-sweden {
    top: 4.9%;
    left: 52%;
    width: 18.5%;
  }

  .illustration-denmark {
    top: 24.5%;
    left: 77%;
    width: 19.5%;
  }

  .illustration-new-zealand {
    top: 46%;
    left: 82.5%;
    width: 23.5%;
  }

  .illustration-australia {
    top: 76%;
    left: 77%;
    width: 18.5%;
  }

  .illustration-avatar {
    position: absolute;
    top: 29%;
    left: 35%;
    width: 25.5%;

    ${media.xs} {
      top: 21%;
    }
  }

  .illustration-action {
    position: absolute;
    top: 42.7%;
    left: 16%;
    width: 68.5%;
  }

  .illustration-desc {
    position: absolute;
    top: 93.3%;
    left: 19%;
    width: 60.5%;
    box-shadow: 0 24px 39px rgba(44, 41, 93, 0.2987);
    border-radius: 12px;
  }
`;

export const ContentRight = styled.div`
  padding-top: 89px;
  padding-left: 80px;
  ${media.md} {
    padding-top: 0;
    padding-left: 0;
  }
`;

export const Grid = styled(SharedGrid)`
  > div {
    ${media.md} {
      flex-direction: column-reverse;
    }
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 229px;

  ${media.lg} {
    margin-bottom: 100px;
  }

  ${media.xs} {
    margin-bottom: 60px;
  }
`;
