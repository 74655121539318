import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const LogoWrapper = styled.div`
  margin-right: 14px;
  ${media.sm} {
    margin-right: 0;
  }
  &:nth-child(2) {
    margin-right: 18px;
    ${media.sm} {
      margin-right: 0;
    }

    .gatsby-image-wrapper {
      width: 106px !important;
      margin-top: -2px;
      ${media.xs} {
        width: 50px !important;
      }
    }
  }
  &:nth-child(3) {
    margin-right: 19px;
    ${media.sm} {
      margin-right: 0;
    }
    .gatsby-image-wrapper {
      ${media.sm} {
        width: 100px !important;
      }
    }
  }

  &:last-child {
    margin-right: -10px;
    ${media.xl} {
      margin-right: 0;
    }

    .gatsby-image-wrapper {
      ${media.sm} {
        width: 96px !important;
      }

      ${media.xs} {
        width: 65px !important;
      }
    }
  }
  .gatsby-image-wrapper {
    ${media.xs} {
      width: 37px !important;
    }
    img {
      object-fit: contain !important;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
  ${media.lg} {
    margin-left: 10px;
  }
  ${media.md} {
    margin-left: 0;
  }
  ${media.sm} {
    width: 100%;
    justify-content: space-between;
    margin-top: 9px;
  }
`;
