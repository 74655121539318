import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Collection } from 'components/blocks/collection-gift-cards';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { SolutionsBanner } from 'components/blocks/solutions-banner';
import { Trusted } from 'components/blocks/trusted';
import { EasyToSend } from 'components/pages/employee-rewards/easy-to-send';
import { Features } from 'components/pages/employee-rewards/features';
import { GlobalWorkforce } from 'components/pages/employee-rewards/global-workforce';
import { Intro } from 'components/pages/employee-rewards/intro';
import { TrackRedemptions } from 'components/pages/employee-rewards/track-redemptions';

import { buildPageMetadata } from '../utils/data-builders';
import { extractItems } from '../utils/extract-items';

const EmployeeRewards = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  const featuresItems = extractItems(acf, 'section7Item');

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Intro
        labelText={acf.section1TextAboveTitle}
        title={acf.section1Title}
        buttonText={acf.section1ButtonText}
        buttonLink={acf.section1ButtonLink}
        bottomText={acf.section1BottomText}
        logos={acf.section1Logos}
      />
      <EasyToSend title={acf.section2Title} description={acf.section2Description} />
      <GlobalWorkforce title={acf.section3Title} description={acf.section3Description} />
      <TrackRedemptions title={acf.section4Title} description={acf.section4Description} />
      <Trusted
        theme="purple-dark"
        title={acf.section5Title}
        description={acf.section5Description}
        image={acf.section5Image}
        imageLogo={acf.section5ImageLogo}
        bottomText={acf.section5BottomText}
        hideBackgroundImage
      />
      <Collection
        theme="purple-dark"
        title={acf.section6Title}
        description={acf.section6Description}
      />
      <Features title={acf.section7Title} items={featuresItems} />
      <SolutionsBanner
        title={acf.section8Title}
        buttonText={acf.section8ButtonText}
        buttonLink={acf.section8ButtonLink}
      />
      <Footer theme="light" />
    </Layout>
  );
};

export default EmployeeRewards;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        section1TextAboveTitle
        section1Title
        section1Description
        section1ButtonText
        section1ButtonLink
        section1BottomText
        section1Logos {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        section2Title
        section2Description

        section3Title
        section3Description
        section4Title
        section4Description
        section5Title
        section5Description
        section5Image {
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section5ImageLogo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 280) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section5BottomText
        section6Title
        section6Description
        section7Title
        section7Item1Title
        section7Item1Description
        section7Item2Title
        section7Item2Description
        section7Item3Title
        section7Item3Description
        section7Item4Title
        section7Item4Description
        section8Title
        section8ButtonText
        section8ButtonLink
      }
    }
  }
`;
