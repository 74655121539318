import styled, { css } from 'styled-components';

import { media, color, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 120px;
  ${media.sm} {
    padding-bottom: 0;
  }
`;

export const TrianglePurpleDarkBg = styled.img`
  position: absolute;
  top: -248px;
  right: -177px;
  z-index: -1;

  ${media.sm} {
    top: -172px;
    right: -114px;
    width: 440px;
  }
`;

export const TriangleBg = styled.img`
  position: absolute;
  top: -313px;
  right: -127px;
  width: 285px;
  z-index: -1;
  ${media.sm} {
    top: -146px;
    right: -207px;
    z-index: 1;
  }
`;

export const ImageCard = styled.img`
  box-shadow: 0 30px 40px -24px rgba(44, 41, 93, 0.5);

  ${media.lg} {
    width: 190px;
    height: auto;
  }
  ${media.sm} {
    width: 95px;
  }
`;

export const ImageStatic = styled.img``;

export const Container = styled(SharedContainer)`
  position: relative;
  border-radius: 16px;
  background-color: ${color.text.tertiary};
  box-shadow: 14px 46px 70px -35px rgba(50, 50, 93, 0.27), 2px 2px 22px 0 rgba(26, 26, 67, 0.01);
  @media (min-width: 1306px) {
    max-width: 1246px;
  }

  ${(p) => css`
    ${p.withoutBgAndShadow &&
    css`
      box-shadow: none;
    `}
  `},
  ${media.sm} {
    padding-top: 38px;
    padding-bottom: 43px;
    box-shadow: none;
  }
`;

export const CollectionWrapper = styled.div`
  position: relative;
  padding-bottom: 107px;
  ${media.sm} {
    padding-bottom: 54px;
  }
`;

export const CollectionTop = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  margin-bottom: 41px;

  animation: 10s ease infinite alternate scroll;
  @keyframes scroll {
    from {
      transform: translateX(-25px);
    }
    to {
      transform: translateX(25px);
    }
  }

  ${media.sm} {
    animation: none;
    margin-bottom: 20px;
    grid-gap: 15px;
  }
`;

export const CollectionBottom = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  animation: 10s ease infinite alternate scroll;
  @keyframes scroll {
    from {
      transform: translateX(-15px);
    }
    to {
      transform: translateX(15px);
    }
  }

  ${media.sm} {
    animation: none;
    grid-gap: 15px;
  }
  svg {
    ${media.lg} {
      width: 190px;
      height: auto;
    }
    ${media.sm} {
      width: 125px;
    }
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 42px;
  line-height: 1.29;
  text-align: center;
  margin: 0 0 34px;
  max-width: 590px;

  ${media.sm} {
    font-size: 24px;
    line-height: 1.5;
    margin: 0 0 20px;
  }
  strong {
    font-weight: normal;
    color: #00c1de;

    ${(p) => css`
      ${p.theme === 'purple-dark' &&
      css`
        color: #9e65bc;
      `}
    `}
  }
  br {
    ${media.xs} {
      display: none;
    }
  }
`;

export const SubTitle = styled(Title)`
  font-size: ${fontSize.md};
  font-weight: normal;
  line-height: 1.7;
  color: #56556c;
  margin: 0;
  text-align: center;
  ${media.sm} {
    font-size: 16px;
    line-height: 1.5;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 89px;
  padding: 75px 20px 0;

  ${media.sm} {
    padding: 0 20px 0;
    margin-bottom: 60px;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 47px 67px 57px 54px;
  box-shadow: inset 0 1px 0 0 #d9dfe4;
  ${media.md} {
    flex-direction: column;
    justify-content: center;
  }
  ${media.sm} {
    padding: 47px 20px 57px 20px;
  }
`;

export const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  ${media.md} {
    flex-direction: column;
  }
  ${media.sm} {
    display: none;
  }
`;

export const FooterText = styled.div`
  margin-left: 40px;
  ${media.lg} {
    max-width: 396px;
  }
  ${media.md} {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    margin-left: 0;
    margin-top: 50px;
    margin-bottom: 40px;
  }
`;

export const FooterTitle = styled(Title)`
  font-size: ${fontSize.lg};
  line-height: 1.33;
  margin-bottom: 6px;
  ${media.md} {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 23px;
    text-align: center;
  }
`;

export const FooterDescription = styled(Title)`
  font-size: 18px;
  font-weight: normal;
  line-height: 1.56;
  color: #838791;
  margin: 0;
  ${media.md} {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }
`;
