import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Illustration = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  right: -293px;
  z-index: -1;
  ${media.lg} {
    top: 140px;
    right: -303px;
  }
  ${media.md} {
    width: 469px !important;
    height: 482px !important;
    top: 100%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 40px;
  margin-bottom: 70px;
  ${media.sm} {
    font-size: 32px;
    font-weight: normal;
    line-height: 1.25;
    margin-bottom: 30px;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
`;

export const ContentRight = styled.div`
  padding-top: 118px;
  padding-left: 45px;
  ${media.md} {
    grid-row-start: 1;
    padding-top: 81px;
    padding-left: 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 500px;
  ${media.lg} {
    grid-template-columns: 1fr 290px;
  }
  ${media.md} {
    display: block;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;

  ${media.md} {
    padding-bottom: 282px;
  }
`;
